var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('BaseForm',{attrs:{"apiActions":"membership/membership.php","backRoute":"/membershiplist","newRecordTitle":"Nova vrsta članarine","listRefreshAction":"membership_list_needs_refresh","formData":[
      {
        title: 'OSNOVNI PODACI',
        rows: [
          {
            id: 1,
            fields: [
              {
                label: 'Naziv',
                type: 'text',
                name: 'name'
              },
              {
                label: 'Godina',
                type: 'text',
                name: 'year'
              },
              {
                label: 'Cijena (EUR)',
                type: 'text',
                name: 'price'
              }
            ]
          }
        ]
      },
      {
        title: 'RAČUNI',
        rows: [
          {
            id: 2,
            fields: [
              {
                label: 'Šalji automatski',
                type: 'toggle',
                name: 'sendinvoice'
              },
              {
                label: 'Dan u mjesecu',
                type: 'text',
                name: 'invoiceday'
              },
              {
                label: 'Mjesec u godini',
                type: 'text',
                name: 'invoicemonth'
              }
            ]
          }
        ]
      },
      {
        title: 'PODSJETNICI',
        rows: [
          {
            id: 3,
            fields: [
              {
                label: 'Šalji automatski',
                type: 'toggle',
                name: 'sendreminder'
              },
              {
                label: 'Dan u mjesecu',
                type: 'text',
                name: 'reminderday'
              },
              {
                label: 'Mjesec u godini',
                type: 'text',
                name: 'remindermonth'
              }
            ]
          },
          {
            id: 4,
            fields: [
              {
                label: 'Razmak u danima',
                type: 'text',
                name: 'reminderspan'
              },
              {
                label: 'Broj ponavljanja',
                type: 'text',
                name: 'remindercount'
              }
            ]
          }
        ]
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }